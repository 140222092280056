import _net from "net";
var exports = {};
const net = _net;
/* istanbul ignore file: https://github.com/nodejs/node/blob/v13.0.1/lib/_http_agent.js */

exports = options => {
  let servername = options.host;
  const hostHeader = options.headers && options.headers.host;

  if (hostHeader) {
    if (hostHeader.startsWith("[")) {
      const index = hostHeader.indexOf("]");

      if (index === -1) {
        servername = hostHeader;
      } else {
        servername = hostHeader.slice(1, -1);
      }
    } else {
      servername = hostHeader.split(":", 1)[0];
    }
  }

  if (net.isIP(servername)) {
    return "";
  }

  return servername;
};

export default exports;