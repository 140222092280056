import _stream from "stream";
var exports = {};
const {
  Readable
} = _stream;

class IncomingMessage extends Readable {
  constructor(socket, highWaterMark) {
    super({
      highWaterMark,
      autoDestroy: false
    });
    this.statusCode = null;
    this.statusMessage = "";
    this.httpVersion = "2.0";
    this.httpVersionMajor = 2;
    this.httpVersionMinor = 0;
    this.headers = {};
    this.trailers = {};
    this.req = null;
    this.aborted = false;
    this.complete = false;
    this.upgrade = null;
    this.rawHeaders = [];
    this.rawTrailers = [];
    this.socket = socket;
    this.connection = socket;
    this._dumped = false;
  }

  _destroy(error) {
    this.req._request.destroy(error);
  }

  setTimeout(ms, callback) {
    this.req.setTimeout(ms, callback);
    return this;
  }

  _dump() {
    if (!this._dumped) {
      this._dumped = true;
      this.removeAllListeners("data");
      this.resume();
    }
  }

  _read() {
    if (this.req) {
      this.req._request.resume();
    }
  }

}

exports = IncomingMessage;
export default exports;