var exports = {};

/* istanbul ignore file: https://github.com/nodejs/node/blob/master/lib/internal/errors.js */
const makeError = (Base, key, getMessage) => {
  exports[key] = class NodeError extends Base {
    constructor(...args) {
      super(typeof getMessage === "string" ? getMessage : getMessage(args));
      this.name = `${super.name} [${key}]`;
      this.code = key;
    }

  };
};

makeError(TypeError, "ERR_INVALID_ARG_TYPE", args => {
  const type = args[0].includes(".") ? "property" : "argument";
  let valid = args[1];
  const isManyTypes = Array.isArray(valid);

  if (isManyTypes) {
    valid = `${valid.slice(0, -1).join(", ")} or ${valid.slice(-1)}`;
  }

  return `The "${args[0]}" ${type} must be ${isManyTypes ? "one of" : "of"} type ${valid}. Received ${typeof args[2]}`;
});
makeError(TypeError, "ERR_INVALID_PROTOCOL", args => {
  return `Protocol "${args[0]}" not supported. Expected "${args[1]}"`;
});
makeError(Error, "ERR_HTTP_HEADERS_SENT", args => {
  return `Cannot ${args[0]} headers after they are sent to the client`;
});
makeError(TypeError, "ERR_INVALID_HTTP_TOKEN", args => {
  return `${args[0]} must be a valid HTTP token [${args[1]}]`;
});
makeError(TypeError, "ERR_HTTP_INVALID_HEADER_VALUE", args => {
  return `Invalid value "${args[0]} for header "${args[1]}"`;
});
makeError(TypeError, "ERR_INVALID_CHAR", args => {
  return `Invalid character in ${args[0]} [${args[1]}]`;
});
export default exports;