import _http from "http2";
import _agent from "./agent";
import _clientRequest from "./client-request";
import _incomingMessage from "./incoming-message";
import _auto from "./auto";
var exports = {};
const http2 = _http;
const agent = _agent;
const ClientRequest = _clientRequest;
const IncomingMessage = _incomingMessage;
const auto = _auto;

const request = (url, options, callback) => {
  return new ClientRequest(url, options, callback);
};

const get = (url, options, callback) => {
  // eslint-disable-next-line unicorn/prevent-abbreviations
  const req = new ClientRequest(url, options, callback);
  req.end();
  return req;
};

exports = { ...http2,
  ClientRequest,
  IncomingMessage,
  ...agent,
  request,
  get,
  auto
};
export default exports;
const _ClientRequest = exports.ClientRequest,
      _IncomingMessage = exports.IncomingMessage,
      _request = exports.request,
      _get = exports.get,
      _auto2 = exports.auto;
export { _ClientRequest as ClientRequest, _IncomingMessage as IncomingMessage, _request as request, _get as get, _auto2 as auto };