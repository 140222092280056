var exports = {};

/* istanbul ignore file: https://github.com/nodejs/node/blob/a91293d4d9ab403046ab5eb022332e4e3d249bd3/lib/internal/url.js#L1257 */
exports = url => {
  const options = {
    protocol: url.protocol,
    hostname: typeof url.hostname === "string" && url.hostname.startsWith("[") ? url.hostname.slice(1, -1) : url.hostname,
    host: url.host,
    hash: url.hash,
    search: url.search,
    pathname: url.pathname,
    href: url.href,
    path: `${url.pathname || ""}${url.search || ""}`
  };

  if (typeof url.port === "string" && url.port.length !== 0) {
    options.port = Number(url.port);
  }

  if (url.username || url.password) {
    options.auth = `${url.username || ""}:${url.password || ""}`;
  }

  return options;
};

export default exports;