var exports = {};

exports = header => {
  switch (header) {
    case ":method":
    case ":scheme":
    case ":authority":
    case ":path":
      return true;

    default:
      return false;
  }
};

export default exports;